import { useAmplitudeTracking } from './useAmplitudeTracking';
import { useGoogleAnalyticsTracking } from './useGoogleAnalyticsTracking';
import { usePirschTracking } from './usePirschTracking';

import { Ampli, ampli } from '@/src/ampli';

import type { AxeptioCookiesChoices } from '@/types/axeptio';

interface UseUserTrackingReturn {
  /**
   * Event tracking instance
   */
  track: Ampli;
}

export function useUserTracking(
  choices?: Ref<AxeptioCookiesChoices>
): UseUserTrackingReturn {
  usePirschTracking();

  const isAmplitudeConsented = computed(() => choices?.value?.amplitude);

  useAmplitudeTracking(isAmplitudeConsented);

  const isGoogleAnalyticsConsented = computed(
    () => choices?.value?.googleAnalytics
  );

  if (isGoogleAnalyticsConsented.value) {
    useGoogleAnalyticsTracking();
  }

  return {
    track: ampli,
  };
}
