import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import { ampli } from '@/src/ampli';

import type { IdentifyProperties } from '@/src/ampli';
import type { WatchStopHandle } from 'vue';

const isInit = ref(false);

export async function useAmplitudeTracking(
  isConsented: ComputedRef<boolean | undefined>
): Promise<void> {
  onMounted(() => {
    if (!isInit.value) {
      watchImmediate(isConsented, () => {
        if (!ampli.isLoaded) {
          load();
        }

        if (!isConsented.value) {
          ampli.client.setOptOut(true);
          return;
        }

        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 1,
        });

        ampli.client.add(sessionReplayTracking);

        ampli.client.setOptOut(false);
      });

      isInit.value = true;
    }

    function load(disabled = false): void {
      ampli.load({
        environment: 'fifteencustomerwebsite',
        disabled,
        client: {
          configuration: {
            serverZone: 'EU',
            defaultTracking: !disabled,
          },
        },
      });
    }

    if (isConsented.value) {
      useUserIdentify();
    }
  });
}

const userProperties = ref<IdentifyProperties>({});

const userId = ref<string>();

/**
 * Set dynamically Amplitude user properties
 */
function useUserIdentify(): void {
  function syncProperty<I extends keyof IdentifyProperties>(
    property: I,
    value: Ref<IdentifyProperties[I]>
  ): { stop: WatchStopHandle } | void {
    if (property in userProperties.value) {
      return;
    }
    watchImmediate(value, newValue => {
      userProperties.value[property] = newValue;
      if (userId.value) {
        ampli.identify(userId.value, userProperties.value);
      }
    });
  }

  function setUserId(id: string | undefined): void {
    ampli.identify(id);
    userId.value = id;
  }
  const clientStore = useClientStore();
  const { clientState } = storeToRefs(clientStore);

  const userStore = storeToRefs(useUserStore());

  // We add a toRef here to ensure the watch source is not undefined (which happens sometimes with user for unknown reasons)
  watchImmediate(userStore.user, user => setUserId(user?.id));

  syncProperty(
    'serviceName',
    toRef(() => clientState.value.name)
  );

  const { locale } = useI18n();
  syncProperty('uiLocale', locale);
}
