export async function useGoogleAnalyticsTracking(): Promise<void> {
  const { googleAnalyticsTrackingId } = useRuntimeConfig().public;

  if (!googleAnalyticsTrackingId) {
    return;
  }

  useHead({
    script: [
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`,
        async: true,
      },
      {
        children: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config',${googleAnalyticsTrackingId}); `,
      },
    ],
  });
}
