export function usePirschTracking(): void {
  const { pirschTrackingCode } = useRuntimeConfig().public;

  if (!pirschTrackingCode) {
    return;
  }

  useHead({
    script: [
      {
        src: 'https://api.pirsch.io/pa.js',
        defer: true,
        id: 'pianjs',
        'data-code': pirschTrackingCode,
      },
    ],
  });
}
